import { Directive, Input } from '@angular/core';
import { ActivatedRoute, RouterLink, UrlTree } from '@angular/router';
import { getRouteParam } from '../utility';

@Directive({
  selector: '[wRouterLink]',
  hostDirectives: [RouterLink],
  standalone: true
})
export class WorkspaceRouterLinkDirective {
  constructor(private routerLink: RouterLink, private route: ActivatedRoute) { }
  @Input({ required: true }) wRouterLink!: string | any[] | UrlTree | null | undefined;
  ngOnInit() {
    const route = this.route;
    const workspace = getRouteParam(route, 'workspace');
    if (workspace) {
      if (typeof this.wRouterLink === 'string') {
        this.wRouterLink = this.wRouterLink.split('/');
        if (this.wRouterLink[0] == '')
          this.wRouterLink = ['/w', workspace, ...this.wRouterLink.slice(1)];
      } else if (Array.isArray(this.wRouterLink)) {
        const [first, ...rest] = this.wRouterLink;
        if (first.startsWith('/w')) {
          if (first != '/w') rest.unshift(first.replace('/w', ''));
          this.wRouterLink = ['/w', workspace, ...rest];
        }
      } else if (this.wRouterLink) {
        this.wRouterLink = ['/w', workspace, this.wRouterLink];
      }
    }
    this.routerLink.routerLink = this.wRouterLink;
    this.routerLink.ngOnChanges();
  }

}

